<template>
	<div class="welcome">
		<div class="pa-5">
			<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
				<span>{{ message }}</span>
			</v-snackbar>
			<v-layout column>
				<v-flex xs12>
					<v-carousel cycle interval="10000" height="500" hide-delimiter-background show-arrows-on-hover
					            style="border-radius: 25px;">
						<v-carousel-item v-for="(ad, i) in allAds" :key="i">
							<v-sheet :color="ad.background_color" height="100%" style="border-radius: 25px;">
								<v-row class="fill-height" align="center" justify="center">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<v-img contain max-height="400" class="mx-15" style="border-radius: 25px;"
											       :src="path + '/storage/ad_pictures/' + ad.image"/>
										</v-flex>
										<v-flex xs12 md8>
											<div :class="'text-h2 font-weight-black mt-15 ' + ad.text_color + '--text'"
											     align="center">
												{{ ad.title }}
											</div>
											<div :class="'text-h6 font-weight-black ' + ad.text_color + '--text'"
											     align="center">
												{{ ad.tagline }}
											</div>
											<div :class="'mt-5 mx-15 ' + ad.text_color + '--text'" align="center"
											     v-html="ad.description">

											</div>
										</v-flex>
									</v-layout>

								</v-row>
							</v-sheet>
						</v-carousel-item>
					</v-carousel>
				</v-flex>
				<v-flex xs12 class="mt-5" v-if="allStudentLevels.length==0">
					<div align="center">
						<v-alert
							outlined
							type="warning"
							prominent
							border="left"
						>
							No Student has been assigned to your account yet/ He hasn't been assigned a Class.
						</v-alert>

					</div>
				</v-flex>
				<v-flex xs12 class="mt-5" v-if="allStudentLevels.length!=0">
					<v-tabs show-arrows v-model="partTab">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab
							@click="assignStudent(index)"
							class="text-none"
							v-for="(allStudentLevel, index) in allStudentLevels"
							:key="allStudentLevel"
						>
							{{ allStudentLevel.student.first_name }} {{ allStudentLevel.student.middle_name }}
							{{ allStudentLevel.student.last_name }}
						</v-tab>
					</v-tabs>
				</v-flex>
				<v-flex xs12 v-if="allStudentLevels.length!=0" class="mt-10">
					<v-layout row wrap>
						<v-flex xs12 md3>
							<v-layout column class="mt-5">
								<v-flex xs12>
									<div class="mx-8" align="center">
										<v-img v-if="allStudentLevels[activeStudent].student.prof_pic == null"
										       src="img/avatar.jpg">
										</v-img>
										<v-img v-else
										       :src="path + '/storage/part_pictures/' + allStudentLevels[activeStudent].student.prof_pic">
										</v-img>

									</div>
								</v-flex>
								<v-flex xs12 v-if="allStudentLevels[activeStudent].student_level!=null">
									<div class="mx-5 mt-5">
										<v-layout row wrap>
											<v-flex xs12 md3>
												<b>Level:</b>
											</v-flex>
											<v-flex xs12 md9>
												{{ allStudentLevels[activeStudent].student_level.level.name }}
											</v-flex>
										</v-layout>
										<v-layout row wrap class="mt-5">
											<v-flex xs12 md3>
												<b>Sub Level:</b>
											</v-flex>
											<v-flex xs12 md9>
												{{ allStudentLevels[activeStudent].student_level.sub_level.name }}
											</v-flex>
										</v-layout>
									</div>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md9>
							<div v-if="allStudentLevels[activeStudent].parts.length!=0">
								<v-layout row wrap class="mx-1 mt-5">

									<template v-for="(part, index) in allStudentLevels[activeStudent].parts">
										<v-flex xs12 md3>
											<div class="mx-1">
												<v-card elevation="0" style="border-radius: 25px;" outlined
												        :loading="loading"
												        class="d-flex flex-column my-1" height="455">
													<template slot="progress">
														<v-progress-linear color="deep-purple" height="10"
														                   indeterminate></v-progress-linear>
													</template>

													<v-img v-if="part.image == null" class="background" contain
													       style="border-radius: 25px;" max-height="180"
													       src="img/book-silhouette-vector-14.png">
														<div align="right" class="mt-1 mr-1">
															<v-btn v-if="part.favourite == false" icon
															       @click="addToWishlist(part)" :loading="wishlistLoading && partIndex == part.id
                                                            ">
																<v-icon small>mdi-heart</v-icon>
															</v-btn>
														</div>
														<div align="right" class="mt-1 mr-1">
															<v-btn v-if="part.favourite == true" icon
															       @click="removeFromWishlist(part)" :loading="wishlistLoading && partIndex == part.id
                                                            ">
																<v-icon class="red--text">mdi-heart</v-icon>
															</v-btn>
														</div>
													</v-img>
													<v-img max-height="180" contain v-else
													       :src="path + '/storage/part_pictures/' + part.image"
													       style="border-radius: 25px;" class="background">
														<div align="right" class="mt-1 mr-1">
															<v-btn v-if="part.favourite == false" icon
															       @click="addToWishlist(part)" :loading="wishlistLoading && partIndex == part.id
                                                            ">
																<v-icon small>mdi-heart</v-icon>
															</v-btn>
														</div>
														<div align="right" class="mt-1 mr-1">
															<v-btn v-if="part.favourite == true" icon
															       @click="removeFromWishlist(part)" :loading="wishlistLoading && partIndex == part.id
                                                            ">
																<v-icon class="red--text">mdi-heart</v-icon>
															</v-btn>
														</div>
													</v-img>

													<div>
														<router-link :to="'/product/' + part.slug"
														             style="text-decoration: none !important">
															<div align="center" class="subtitle mt-5">
																<v-clamp autoresize :max-lines="2">
																	{{ part.name }}
																</v-clamp>
															</div>
														</router-link>
													</div>
													<div align="center" class="mt-5 primary--text">
														Kshs {{ part.price }}
													</div>
													<div class="mx-1 mt-4" v-if="part.purchased_quantity!=0">
														<v-alert
															type="success"
														>
															<div align="center">
																Purchased ({{ part.purchased_quantity }})
															</div>
														</v-alert>
													</div>
													<div align="center" class="mt-9 red--text"
													     v-if="part.quantity==0 && part.purchased_quantity==0">
														Out of Stock
													</div>
													<v-spacer></v-spacer>
													<v-divider class="mx-4"></v-divider>
													<div class="my-2 mx-2">

														<v-btn v-if="part.quantity!=0" rounded block text
														       class="accent--text text-none"
														       @click="addToCart(part)" :loading="cartLoading && partIndex == part.id
                                                        ">
															Add to Cart
															<v-icon small right>
																mdi-cart
															</v-icon>
														</v-btn>
														<v-btn v-if="part.quantity==0" disabled rounded block text
														       class="accent--text text-none">
															Add to Cart
															<v-icon small right>
																mdi-cart
															</v-icon>
														</v-btn>
													</div>
												</v-card>
											</div>
										</v-flex>
									</template>


								</v-layout>
							</div>
							<div v-else>
								<div align="center">
									<v-alert
										outlined
										type="warning"
										prominent
										border="left"
									>
										No Stock Items Found Just Yet. Kindly Check Back Later.
									</v-alert>
								</div>
							</div>

						</v-flex>
					</v-layout>
				</v-flex>

			</v-layout>
		</div>
	</div>
</template>
<style>
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
<script>
import Vue from 'vue'
import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'
import apiCall from "@/utils/api";
import { Roles } from "@/constants/Roles";
import { mapActions, mapGetters } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import VClamp from "vue-clamp";

Vue.use(VueScrollProgressBar)

Vue.use(CKEditor);

export default {
	components: {
		VClamp,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			color: "",
			message: "",
			delete: false,
			snackbar: false,
			loading: false,
			drawer: false,
			dialog: false,

			partTab: null,
			activeStudent: 0,

			partCarousel: 0,
			partArray: [],
			partLoading: false,

			cartLoading: false,
			wishlistLoading: false,
			partIndex: null,

			editor: ClassicEditor,
			editorConfig: {},

		};
	},
	created() {
		console.log(this.getProfile.roles)
		console.log('User:', this.getProfile.roles.some(r => r.name == Roles.POS_STANDARD_USER))
		this.getProfile.roles.forEach(r => {
			console.log(r.name, Roles.POS_STANDARD_USER);
		})
		window.scrollTo(0, 0);
		this.fetchAllStudentLevels();
		this.fetchAllAds()
	},
	methods: {
		...mapActions([
			'fetchAllAds',

			'fetchAllStudentLevels',
			"fetchCarts",
			"fetchWishlists"
		]),
		...mapGetters([
			"getProfile",
		]),

		assignStudent(index) {
			this.activeStudent = index
		},

		addToCart(item) {
			this.cartLoading = true;
			this.partIndex = item.id;
			apiCall({
				url: "/api/cart",
				data: item,
				method: "POST",
			})
				.then((resp) => {
					if (resp.status == 200) {
						this.message = resp.message;
						this.color = "success";
						this.cartLoading = false;
						this.snackbar = true;

						this.fetchAllStudentLevels();
						this.fetchCarts(1)
					} else if (resp.status == 409) {
						this.message = resp.message;
						this.color = "error";
						this.cartLoading = false;
						this.snackbar = true;
					}
				})
				.catch(() => {
					this.message = "An Error Occurred";
					this.color = "error";
					this.cartLoading = false;
					this.snackbar = true;
				});
		},

		addToWishlist(item) {
			this.wishlistLoading = true;
			this.partIndex = item.id;
			apiCall({
				url: "/api/wishlist",
				data: item,
				method: "POST",
			})
				.then((resp) => {
					if (resp.status == 200) {
						this.message = resp.message;
						this.color = "success";
						this.wishlistLoading = false;
						this.snackbar = true;

						this.fetchAllStudentLevels();
						this.fetchWishlists(1)
					} else if (resp.status == 409) {
						this.message = resp.message;
						this.color = "error";
						this.wishlistLoading = false;
						this.snackbar = true;
					}
				})
				.catch((error) => {
					this.message = "An Error Occurred";
					this.color = "error";
					this.wishlistLoading = false;
					this.snackbar = true;
				});
		},

		removeFromWishlist(item) {
			this.wishlistLoading = true;
			this.partIndex = item.id;
			apiCall({ url: "/api/wishlist/" + item.id, method: "DELETE" })
				.then((resp) => {
					this.message = "Item removed from wishlist successfully";
					this.color = "success";
					this.snackbar = true;
					this.wishlistLoading = false;
					this.delete = false;
					this.partIndex = null;
					this.fetchAllStudentLevels();
					this.fetchWishlists(1)
				})
				.catch((error) => {
					this.message = "anErrorOccurredMsg";
					this.color = "error";
					this.snackbar = true;
					this.wishlistLoading = false;
					this.delete = false;
					this.partIndex = null;
					console.log(error.response);
				});
		},

	},
	computed: {
		...mapGetters(['allAds', 'getProfile', 'allStudentLevels', 'studentLevelLoader']),
	},
};
</script>
  