import apiCall from '@/utils/api'

const state = {
    openDiscards: [],
    searchDiscards: [],
    closedDiscards: [],
    publicDiscards: [],
    openDiscardPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    searchDiscardPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    closedDiscardPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },
    publicDiscardPagination: {
        search: ' ',
        current_page: 1,
        per_page: 0,
        total: 0,
        visible: 10
    },

    openDiscardLoader: false,
    closedDiscardLoader: false,
    publicDiscardLoader: false,
    searchDiscardLoader: false,
};

const getters = {
    openDiscards: (state) => state.openDiscards,
    closedDiscards: (state) => state.closedDiscards,

    openDiscardLoader: (state) => state.openDiscardLoader,
    closedDiscardLoader: (state) => state.closedDiscardLoader,

    openDiscardPagination: (state) => state.openDiscardPagination,
    closedDiscardPagination: (state) => state.closedDiscardPagination,
}

const actions = {
    async fetchOpenDiscards({commit}, page) {
        const response = await apiCall({url: `/api/discarded-stocks?status=pending&page=${page}`, method: 'GET'});
        commit('setOpenDiscards', response)
        commit('stopOpenDiscardLoader', response)
    },
    async fetchClosedDiscards({commit}, page) {
        const response = await apiCall({url: `/api/discarded-stocks?status=closed&page=${page}`, method: 'GET'});
        commit('setClosedDiscards', response)
        commit('stopClosedDiscardLoader', response)
    },

    async filterOpenDiscards({commit, state}, resp) {
        commit('setOpenDiscards', resp)
    },
    async filterClosedDiscards({commit, state}, resp) {
        commit('setClosedDiscards', resp)
    },
    async startOpenDiscardLoader({commit, state}, resp) {
        commit('startOpenDiscardLoader', resp)
    },
    async startClosedDiscardLoader({commit, state}, resp) {
        commit('startClosedDiscardLoader', resp)
    },
    async stopOpenDiscardLoader({commit, state}, resp) {
        commit('stopOpenDiscardLoader', resp)
    },
    async stopClosedDiscardLoader({commit, state}, resp) {
        commit('stopClosedDiscardLoader', resp)
    },
};

const mutations = {
    setOpenDiscards: (state, openDiscards) => {
        state.openDiscards = openDiscards.data
        state.openDiscardPagination.current_page = openDiscards.current_page
        state.openDiscardPagination.total = openDiscards.total
        state.openDiscardPagination.per_page = openDiscards.per_page
    },
    setClosedDiscards: (state, closedDiscards) => {
        state.closedDiscards = closedDiscards.data
        state.closedDiscardPagination.current_page = closedDiscards.current_page
        state.closedDiscardPagination.total = closedDiscards.total
        state.closedDiscardPagination.per_page = closedDiscards.per_page
    },
    setPublicDiscards: (state, publicDiscards) => {
        state.publicDiscards = publicDiscards.data
        state.publicDiscardPagination.current_page = publicDiscards.current_page
        state.publicDiscardPagination.total = publicDiscards.total
        state.publicDiscardPagination.per_page = publicDiscards.per_page
    },
    setSearchDiscards: (state, sales) => {
        state.searchDiscards = sales.data
        state.searchDiscardPagination.current_page = sales.current_page
        state.searchDiscardPagination.total = sales.total
        state.searchDiscardPagination.per_page = sales.per_page
    },
    startSearchDiscardLoader: (state) => state.searchDiscardLoader = true,
    stopSearchDiscardLoader: (state) => state.searchDiscardLoader = false,

    startOpenDiscardLoader: (state) => state.openDiscardLoader = true,
    stopOpenDiscardLoader: (state) => state.openDiscardLoader = false,
    startClosedDiscardLoader: (state) => state.closedDiscardLoader = true,
    stopClosedDiscardLoader: (state) => state.closedDiscardLoader = false,
    startPublicDiscardLoader: (state) => state.publicDiscardLoader = true,
    stopPublicDiscardLoader: (state) => state.publicDiscardLoader = false,
};

export default {
    state,
    getters,
    actions,
    mutations
}
