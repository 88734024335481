<template>
	<div class="openDiscards" v-if="$can('view-stock-discard-requests')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-card elevation="0" class="mt-5" v-if="view == 'open' || view == 'closed'">
				<v-tabs show-arrows v-model="viewTab">
					<v-tabs-slider color="primary"></v-tabs-slider>
					<v-tab @click="changeCurrentView('open')">
						<div class="text-none primary--text">Open Discard or Return Requests ({{
								openDiscardPagination.total
							}})
						</div>
					</v-tab>
					<v-tab @click="changeCurrentView('closed')">
						<div class="text-none primary--text">Closed Discard or Return Requests ({{
								closedDiscardPagination.total
							}})
						</div>
					</v-tab>
				</v-tabs>
			</v-card>

			<v-divider class="mt-5"></v-divider>

			<div v-if="view == 'open'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="openDiscardLoader" height="1" indeterminate color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												openDiscardPagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
                                    append-icon="mdi-undo-variant" @click:append="resetPendingDiscardsSearch()"
                                    v-on:keyup.enter="searchPendingDiscards" v-model="pendingDiscardsSearchTerm"
                                    @input="enablePendingDiscardsSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchPendingDiscards" :loading="searchPendingDiscardsLoader"
                             :disabled="pendingDiscardsSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="openDiscards?.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Open Discard or Return Requests Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Stock No</th>
													<th class="text-left text--text">Item</th>
													<th class="text-left text--text">Supplier</th>
													<th class="text-left text--text">Quantity</th>
                          <th class="text-left text--text">Action</th>
                          <th class="text-left text--text">Initiator</th>
                          <th class="text-left text--text">Reason</th>
													<th class="text-center text--text">Buying Price</th>
													<th class="text-center text--text">Selling Price</th>
													<th class="text-left text--text">Creation Date</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in openDiscards" :key="item.id">
													<td>
														<div>{{ item.stock.stock_no }}</div>
													</td>
													<td>
														{{ item.stock.part.name }}
													</td>
													<td>
                            {{item.stock.supplier.name }}
													</td>
													<td>
															{{ item.quantity }}
													</td>
													<td>
                            {{ item.action | formatText }}
                          </td>
                          <td>
                            {{ item.initiator.full_name }}
                          </td>
													<td :title="item.reason ?? 'No Reason'">
														<span style="max-width:20vw;overflow:hidden;display:-webkit-box;line-clamp:2;-webkit-line-clamp:2;-webkit-box-orient:vertical;">
															{{ item.reason ?? 'No Reason' }}
														</span>
													</td>
                          <td class="text-center">
                            {{ item.stock.buying_price }}
                          </td>
                          <td class="text-center">
                            {{ item.stock.selling_price }}
                          </td>
													<td>
														<div>
                              {{item.created_at | moment("DD/MM/YYYY") }}
														</div>
														<small>
                              {{item.created_at | moment("hh:mm A") }}
                            </small>
													</td>
													<td>
														<div align="right">
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text" v-bind="attrs" v-on="on"
																			 @click="showDiscardRequest(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Request</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(discard, index) in openDiscards">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title title--text ml-3">
																				<b>Sale No</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>

																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item</b>
																			</div>
																			<div>
																				{{
																					discard.stock.part.name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Unit Price (Kshs)</b>
																			</div>
																			<div>
																				{{
																					discard.stock.buying_price
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Quantity</b>
																			</div>
																			<div>
																				{{
																					discard.quantity
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total (Kshs)</b>
																			</div>
																			<div>
																				{{ discard.stock.buying_price * discard.quantity }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Initiator</b>
																			</div>
																			<div>
																				{{ discard.initiator?.full_name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					discard.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showDiscardRequest(discard)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="openDiscardsLength != 0" :length="openDiscardsLength" total-visible="10"
                              v-model="openDiscardPagination.current_page" @input="changeOpenDiscardsPage()"
                              circle>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</v-layout>
			</div>
			<div v-if="view == 'closed'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="closedDiscardLoader" height="1" indeterminate color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												closedDiscardPagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
                                    append-icon="mdi-undo-variant" @click:append="resetClosedDiscardsSearch()"
                                    v-on:keyup.enter="searchClosedDiscardRequests" v-model="closedDiscardsSearchTerm"
                                    @input="enableClosedDiscardsSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchClosedDiscardRequests"
													 :loading="searchClosedDiscardsLoader"
													 :disabled="closedDiscardsSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="closedDiscards?.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Closed Discard or Return Requests Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Item</th>
													<th class="text-right text--text">Quantity</th>
													<th class="text-right text--text">Total (Kshs)</th>
													<th class="text-left text--text">Initiator</th>
													<th class="text-left text--text">Reviewer</th>
													<th class="text-left text--text">Status</th>
													<th class="text-left text--text">Created</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in closedDiscards" :key="item.id">
													<td>
														{{ item.stock.part.name }}
													</td>
													<td>
														<div align="right">
															{{ item.quantity }}
														</div>
													</td>
													<td>
														<div align="right">
                              {{ item.stock.buying_price * item.quantity }}
														</div>
													</td>
													<td>
														{{ item.initiator?.full_name }}
													</td>
													<td>
														{{ item.approver?.full_name }}
													</td>
													<td>{{ item.status.toUpperCase() }}</td>
													<td>
														{{
															item.created_at | moment("DD/MM/YYYY - hh:mm a")
														}}
													</td>
													<td>
														<div align="right">
															<v-tooltip top>
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text"
																			 @click="showDiscardRequest(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Sale</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(discard, index) in closedDiscards">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item</b>
																			</div>
																			<div>
																				{{ discard.stock.part.name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Unit Price (Kshs)</b>
																			</div>
																			<div>
																				{{ discard.stock.buying_price }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Quantity</b>
																			</div>
																			<div>
																				{{ discard.quantity }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total (Kshs)</b>
																			</div>
																			<div>
                                        {{ discard.stock.buying_price * discard.quantity }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Initiator</b>
																			</div>
																			<div>
																				{{ discard.initiator?.full_name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Reviewer</b>
																			</div>
																			<div>
																				{{ discard.approver?.full_name }}
																			</div>
																		</v-flex>


																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					discard.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showDiscardRequest(discard)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="closedDiscardsLength != 0" :length="closedDiscardsLength" total-visible="10"
                              v-model="closedDiscardPagination.current_page"
                              @input="changeClosedDiscardsPage()" circle>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</v-layout>
			</div>
			<div v-if="view == 'show'">
				<v-card outlined class="mt-2">
					<v-card-title class="accent text--text">
            {{ assignedDiscard.stock.stock_no }} - Discard Request
            <v-spacer></v-spacer>
						<v-btn v-if="prevView=='open'" icon @click="changeView('open')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
						<v-btn v-if="prevView=='closed'" icon @click="changeView('closed')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<div class="pa-3">
							<div class="mx-3 mt-7">
								<v-layout row wrap>
									<v-flex xs12 md8>
                    <v-layout column >
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs11>
                            <div><b>Request Details:</b></div>
                          </v-flex>
                          <v-flex xs1>

                          </v-flex>
                        </v-layout>

                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Initiator:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{assignedDiscard.initiator.full_name }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Quantity:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{
                              assignedDiscard.quantity
                            }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Reason:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{
                              assignedDiscard.reason
                            }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Creation Date:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{
                              assignedDiscard.created_at | moment("DD/MM/YYYY - hh:mm a")
                            }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Status:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            <div v-if="assignedDiscard.status=='approved'"
                                 class="green--text">
                              Approved
                            </div>
                            <div v-if="assignedDiscard.status=='declined'"
                                 class="red--text">
                              Rejected
                            </div>
                            <div v-if="assignedDiscard.status=='pending'">
                              Pending
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

										<v-layout column class="mt-10">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Stock Details:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Stock No:</b></div>
													</v-flex>
													<v-flex xs12 md9>
                            {{ assignedDiscard.stock.stock_no }}
                          </v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Invoice No:</b></div>
													</v-flex>
													<v-flex xs12 md9>
                            {{ assignedDiscard.stock.invoice_no }}
                          </v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Supplier:</b></div>
													</v-flex>
													<v-flex xs12 md9>
                            {{ assignedDiscard.stock.supplier.name }}
                          </v-flex>
												</v-layout>
											</v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Selling Price:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{ assignedDiscard.stock.selling_price }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Quantity:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{ assignedDiscard.stock.current_quantity }}
                          </v-flex>
                        </v-layout>
                      </v-flex>

										</v-layout>
                    <v-layout column class="mt-10">
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs11>
                            <div><b>Item Details</b></div>
                          </v-flex>
                          <v-flex xs1>

                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Name:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{
                              assignedDiscard.stock.part.name
                            }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <div><b>Serial No:</b></div>
                          </v-flex>
                          <v-flex xs12 md9>
                            {{
                              assignedDiscard.stock.part.serial_no
                            }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap class="mt-10 mb-5">
                      <v-flex xs12 md6>
                        <div class="mx-1 my-1">
                          <v-btn
                              v-if="$can('approve-stock-discard-request') && (assignedDiscard.status=='pending')"
                              depressed block
                              class="green text-none white--text"
                              @click="approveDiscard(assignedDiscard)"
                              :loading="discardLoading && discardStockIndex == assignedDiscard.id">
                            Approve {{ assignedDiscard.action==='discarded'?"Discard": "Return" }} Request
                            <v-icon right>
                              mdi-check-all
                            </v-icon>
                          </v-btn>
                          <v-btn
                              v-if="$can('approve-stock-discard-request') && (assignedDiscard.status=='approved' || assignedDiscard.status=='declined')"
                              depressed block disabled
                              class="green text-none white--text">
                            Approve {{ assignedDiscard.action==='discarded'?"Discard": "Return" }} Request
                            <v-icon right>
                              mdi-check-all
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs12 md6>
                        <div class="mx-1 my-1">
                          <v-btn
                              v-if="$can('decline-stock-discard-request') && (assignedDiscard.status=='pending')"
                              depressed block
                              class="secondary text-none white--text"
                              @click="declineRequest(assignedDiscard)"
                              :loading="rejectDiscardStockLoading && discardStockIndex == assignedDiscard.id">
                            Reject {{ assignedDiscard.action==='discarded'?"Discard": "Return" }} Request
                            <v-icon right>
                              mdi-cancel
                            </v-icon>
                          </v-btn>
                          <v-btn
                              v-if="$can('decline-stock-discard-request') && (assignedDiscard.status=='approved' || assignedDiscard.status=='declined')"
                              depressed block disabled
                              class="secondary text-none white--text">
                            Reject {{ assignedDiscard.action==='discarded'?"Discard": "Return" }} Request
                            <v-icon right>
                              mdi-cancel
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
								</v-layout>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<logData v-if="logDataModel" v-model="logDataModel"/>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});
Vue.filter("formatText", function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});


Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			view: "open",
			prevView: "open",
			loading: false,
			activateLoading: false,
			deactivateLoading: false,
			broadcastLoading: false,
			discardLoading: false,
			rejectDiscardStockLoading: false,

			editor: ClassicEditor,
			editorConfig: {},

			menu1: false,
			menu2: false,
			allowedStep: (m) => m % 15 === 0,
			valid: true,
			startDatePicker: false,
			endDatePicker: false,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			discardStockIndex: null,
			snackbar: false,
			inputRules: [(v) => !!v || "Input is required"],

			coverImageDialog: false,
			imageDialog: false,
			imageLoading: false,
			fileRules: [(v) => !!v || "Kindly select a File"],
			imageFile: null,
			imageValid: true,

			pendingDiscardsSearchTerm: null,
			closedDiscardsSearchTerm: null,
			searchPendingDiscardsLoader: false,
			searchClosedDiscardsLoader: false,
			pendingDiscardsSearchButtonDisabled: false,
			closedDiscardsSearchButtonDisabled: false,
			filteredPendingDiscards: false,
			filteredClosedDiscards: false,

			viewTab: null,
			newSalePartStock: {
				id: null,
			},
			assignedDiscard: null,
			assignedResponse: null,
			formData: null,
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{name: "January"},
				{name: "February"},
				{name: "March"},
				{name: "April"},
				{name: "May"},
				{name: "June"},
				{name: "July"},
				{name: "August"},
				{name: "September"},
				{name: "October"},
				{name: "November"},
				{name: "December"},
			],

			logData: {
				message: null,
				model: null,
			},
		};
	},
	created() {
		if (this.$can("view-stock-discard-requests")) {
			this.startOpenDiscardLoader();
			this.startClosedDiscardLoader();
			this.fetchOpenDiscards(this.openDiscardPagination.current_page);
			this.fetchClosedDiscards(this.closedDiscardPagination.current_page);
		}
	},
	methods: {
		...mapActions([
			"fetchOpenDiscards",
			"fetchClosedDiscards",
			"startOpenDiscardLoader",
			"startClosedDiscardLoader",
			"startOpenDiscardLoader",
			"stopClosedDiscardLoader",
			"filterOpenDiscards",
			"filterClosedDiscards",

			"filterLogData",
			"openLogDataModel",
		]),
		changeCurrentView(view) {
			this.prevView = view
			this.changeView(view)
		},
		enablePendingDiscardsSearch() {
			this.pendingDiscardsSearchButtonDisabled = false;
		},
		enableClosedDiscardsSearch() {
			this.closedDiscardsSearchButtonDisabled = false;
		},
		resetPendingDiscardsSearch() {
			this.pendingDiscardsSearchTerm = null;
			this.filteredClosedDiscards = true;
			this.startOpenDiscardLoader();
			this.fetchOpenDiscards(1);
		},
		resetClosedDiscardsSearch() {
			this.closedDiscardsSearchTerm = null;
			this.filteredClosedDiscards = true;
			this.stopClosedDiscardLoader();
			this.fetchClosedDiscards(1);
		},
    showDiscardRequest(discardItem) {
			this.assignedDiscard = discardItem;
			this.changeView("show");
		},

		searchPendingDiscards() {
			if (this.pendingDiscardsSearchTerm == null) {
				this.pendingDiscardsSearchButtonDisabled = true;
			} else {
				this.searchPendingDiscardsLoader = true;
				this.pendingDiscardsSearchButtonDisabled = false;
				this.startOpenDiscardLoader();
				apiCall({
					url: "/api/discarded-stocks?status=pending&typeB=search&search=" + this.pendingDiscardsSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenDiscards(resp);
							this.searchPendingDiscardsLoader = false;
							this.startOpenDiscardLoader();
							this.filteredPendingDiscards = true;
						})
						.catch((error) => {
							console.log(error.response);
							this.searchPendingDiscardsLoader = false;
							this.startOpenDiscardLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
						});
			}
		},
		searchClosedDiscardRequests() {
			if (this.closedDiscardsSearchTerm == null) {
				this.closedDiscardsSearchButtonDisabled = true;
			} else {
				this.searchClosedDiscardsLoader = true;
				this.closedDiscardsSearchButtonDisabled = false;
				this.stopClosedDiscardLoader();
				apiCall({
					url: "/api/discarded-stocks?status=closed&typeB=search&search=" + this.closedDiscardsSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedDiscards(resp);
							this.searchClosedDiscardsLoader = false;
							this.stopClosedDiscardLoader();
							this.filteredPendingDiscards = true;
						})
						.catch((error) => {
							this.searchClosedDiscardsLoader = false;
							this.stopClosedDiscardLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			}
		},
		changeView(view) {
			this.view = view;
		},
		changeOpenDiscardsPage() {
			this.startOpenDiscardLoader();
			if (this.filteredPendingDiscards == true) {
				this.pendingDiscardsSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/discarded-stocks?status=pending&page=" +
							this.openDiscardPagination.current_page +
							"&typeB=search&search=" +
							this.pendingDiscardsSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenDiscards(resp);
							this.startOpenDiscardLoader();
						})
						.catch((error) => {
							this.startOpenDiscardLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchOpenDiscards(this.openDiscardPagination.current_page);
			}
		},
		changeClosedDiscardsPage() {
			this.startClosedDiscardLoader();
			if (this.filteredClosedDiscards == true) {
				this.closedDiscardsSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/sale-part-stock-refunds?type=closed&page=" +
							this.closedDiscardPagination.current_page +
							"&typeB=search&search=" +
							this.closedDiscardsSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedDiscards(resp);
							this.stopClosedDiscardLoader();
						})
						.catch((error) => {
							this.stopClosedDiscardLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchClosedDiscards(this.closedDiscardPagination.current_page);
			}
		},
    approveDiscard(item) {
			confirm(`Are You Sure You Want to Approve the ${item.action==='discarded'?'Discard': 'Return'} Request?`) &&
			(this.activate = true);
			if (this.activate) {
				this.discardLoading = true;
				this.discardStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: `/api/discarded-stocks/${item.id}/approve`,
					method: "POST",
				})
						.then(() => {
							this.message = "Stock Item Discarded Successfully";
							this.color = "success";
							this.snackbar = true;
							this.discardLoading = false;
							this.activate = false;
							this.discardStockIndex = null;
							this.assignedDiscard = item
							this.fetchOpenDiscards(this.openDiscardPagination.current_page);
              this.fetchClosedDiscards(this.openDiscardPagination.current_page);
              this.changeView('open')
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.discardLoading = false;
							this.activate = false;
							this.discardStockIndex = null;
							console.log(error.response);
              this.changeView('open')
						});
			}
		},

		declineRequest(item) {
			confirm(`Are You Sure You Want to Reject the ${item.action==='discarded'?'Discard': 'Return'} Request?`) &&
			(this.activate = true);
			if (this.activate) {
				this.rejectDiscardStockLoading = true;
				this.discardStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: `/api/discarded-stocks/${item.id}/decline`,
					method: "POST",
				})
						.then(() => {
							this.message = "Stock Discard Rejected Successfully";
							this.color = "success";
							this.snackbar = true;
							this.rejectDiscardStockLoading = false;
							this.activate = false;
							this.discardStockIndex = null;
							this.assignedDiscard = item
							this.fetchOpenDiscards(this.openDiscardPagination.current_page);
							this.fetchClosedDiscards(this.openDiscardPagination.current_page);
              this.changeView('open')
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.rejectDiscardStockLoading = false;
							this.activate = false;
							this.discardStockIndex = null;
							console.log(error.response);
              this.changeView('open')
						});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/sales?type=formData",
				method: "GET",
			})
					.then(r => this.formData = r)
					.catch(() => {
						this.message = "An Error Occurred; Couldn't Get Form Data";
						this.color = "error";
					});
		},
		viewLog(data) {
			this.logData.message = data.id;
			this.logData.model = "App\\Models\\Stock"
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},
	},
	computed: {
		...mapGetters([
			"openDiscards", "openDiscardPagination", "openDiscardLoader",
			"closedDiscards", "closedDiscardPagination", "closedDiscardLoader",
			"logDataModel"
		]),
		dateToday() {
			var today = new Date();

			var timestamp =
					today.getFullYear() +
					"-" +
					this.months[today.getMonth()] +
					"-" +
					("0" + today.getDate()).slice(-2);
			return timestamp;
		},

		openDiscardsLength: function () {
			return Math.ceil(
					this.openDiscardPagination.total / this.openDiscardPagination.per_page
			);
		},
		closedDiscardsLength: function () {
			return Math.ceil(
					this.closedDiscardPagination.total / this.closedDiscardPagination.per_page
			);
		},
	},
};
</script>
  