<template>
	<div class="welcome">
		<div class="pa-5">
			<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
				<span>{{ message }}</span>
			</v-snackbar>
			<v-layout column>
				<v-flex xs12>
					<v-tabs background-color="primary" dark next-icon="mdi-arrow-right-bold-box-outline"
					        prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
						<v-tabs-slider color="accent"></v-tabs-slider>
						<v-tab v-for="(sale, index) in sales" :key="index" :href="'#tab-' + index"
						       @click="activeSale=index">
							Sale {{ index + 1 }}
							<v-btn icon class="ml-3" @click="closeSale(index)">
								<v-icon small>mdi-close</v-icon>
							</v-btn>
						</v-tab>
						<v-tab>
							<v-btn icon @click="addSale()">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-tab>
					</v-tabs>
				</v-flex>
				<v-flex xs12>
					<v-layout row wrap>
						<v-flex xs12 md8>

							<div id="cart" class="pa-5">
								<v-layout column>
									<v-flex xs12>
										<div v-if="sales.length == 0">
											<v-card elevation="0">
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> NO ITEM FOUND</v-col>
													</v-row>
												</v-container>
											</v-card>
										</div>
										<div v-else>

											<v-layout column>
												<v-flex xs12 v-if="sales[activeSale].status=='incomplete'">
													<v-layout row wrap>
														<v-flex xs12 md12>
															<div class="mx-1 mt-3">
																<v-autocomplete v-model="sales[activeSale].part_id"
																                autofocus
																                :loading="loading" :items="items"
																                :search-input.sync="searchParts"
																                item-value="id"
																                item-text="name"
																                class="text_field background my-2" flat
																                hide-no-data
																                hide-details outlined dense
																                placeholder="Search"
																                @change="pushPart()">
																</v-autocomplete>
															</div>
														</v-flex>
													</v-layout>
												</v-flex>
												<v-flex xs12 v-if="sales[activeSale].items.length!=0">
													<div v-if="sales[activeSale].part!=null" class="mx-1 mt-7">
														<v-layout row wrap>
															<v-flex xs12 md6>
																<v-img v-if="sales[activeSale].part.image==null"
																       class="background" contain
																       style="border-radius: 25px;" max-height="200"
																       src="img/book-silhouette-vector-14.png">

																</v-img>
																<v-img
																	v-else
																	max-height="200"
																	contain
																	:src="path+'/storage/part_pictures/'+sales[activeSale].part.image"
																	style="border-radius: 25px;"
																	class="background"
																>
																</v-img>
															</v-flex>
															<v-flex xs12 md6>
																<v-layout column>
																	<v-flex xs12>
																		<div class="title primary--text ml-5">
																			{{ sales[activeSale].part.name }}
																			({{ sales[activeSale].part.serial_no }})
																		</div>
																	</v-flex>
																	<v-divider class="mt-3 mr-5 ml-5"></v-divider>
																	<v-flex xs12>
																		<div class="pa-8">
																			<v-layout row wrap>
																				<v-flex xs12 md8>
																					{{ sales[activeSale].part.brief }}
																				</v-flex>
																			</v-layout>
																			<v-layout row wrap>
																				<v-flex xs12 md8>
																					<b>Maximum Allowed Purchases:</b>
																				</v-flex>
																				<v-flex xs12 md4>
																					<div
																						v-if="sales[activeSale].part.max_purchase==null"
																						class="green--text"
																						align="right">
																						No Restrictions
																					</div>
																					<div v-else align="right">
																						{{
																							sales[activeSale].part.max_purchase
																						}}
																					</div>
																				</v-flex>
																			</v-layout>
																			<v-layout row wrap>
																				<v-flex xs12 md8>
																					<b>Remaining Stock:</b>
																				</v-flex>
																				<v-flex xs12 md4>
																					<div align="right">
																						{{
																							sales[activeSale].part.quantity
																						}}
																					</div>
																				</v-flex>
																			</v-layout>
																			<v-layout row wrap>
																				<v-flex xs12 md8>
																					<b>Unit Price:</b>
																				</v-flex>
																				<v-flex xs12 md4>
																					<div align="right">
																						Kshs {{
																							sales[activeSale].part.price | formatNumber
																						}}
																					</div>
																				</v-flex>
																			</v-layout>
																		</div>
																	</v-flex>
																</v-layout>
															</v-flex>
														</v-layout>
													</div>

												</v-flex>
												<v-flex xs12>
													<div class="mt-10"><b>Cart</b></div>
												</v-flex>
												<v-flex xs12>
													<div v-if="sales[activeSale].items.length==0" class="mt-5">
														<v-card elevation="0" class="background"
														        style="border-radius: 25px;">
															<v-layout row wrap>
																<v-flex xs12 md1>
																	<v-container fill-height fluid>
																		<v-row align="center" justify="center">
																			<v-col class="text-center">
																				<v-icon large class="primary--text">
																					mdi-alert-circle-outline
																				</v-icon>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-flex>
																<v-flex xs12 md11>
																	<v-container fill-height fluid>
																		<v-row align="center" justify="center">
																			<v-col class="text-center">
																				No Item Added to Cart
																			</v-col>
																		</v-row>
																	</v-container>
																</v-flex>
															</v-layout>
														</v-card>
													</div>
													<div v-if="sales[activeSale].items.length!=0" class="mt-5" style="
                                                        margin: 4px, 4px;
                                                        padding: 8px;
                                                        width: auto;
                                                        height: 53vh;
                                                        overflow-x: hidden;
                                                        overflow-y: scroll;
                                                        text-align: justify;
                                                    ">
														<v-layout row wrap>
															<template v-for="(item, index) in sales[activeSale].items">
																<v-flex xs12 md4>
																	<div class="mx-1 my-1">
																		<v-layout column>
																			<v-flex xs12>
																				<v-img v-if="item.part.image==null"
																				       class="background" contain
																				       style="border-radius: 25px;"
																				       max-height="180"
																				       src="img/book-silhouette-vector-14.png">

																				</v-img>
																				<v-img
																					v-else
																					max-height="180"
																					contain
																					:src="path+'/storage/part_pictures/'+item.part.image"
																					style="border-radius: 25px;"
																					class="background"
																				>
																				</v-img>
																			</v-flex>
																			<v-flex xs12>
																				<div class="mt-2"><b>{{
																						item.part.name
																					}}</b></div>
																			</v-flex>
																			<v-flex xs12>
																				<v-divider
																					class="mb-3 mt-1 mx-5"></v-divider>
																			</v-flex>
																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<div class="ml-3"><b>Unit
																							Price:</b></div>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div align="right" class="mr-3">
																							Kshs {{
																								item.part.price | formatNumber
																							}}
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-7">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<div class="ml-3 mt-1"><b>Quantity:</b>
																						</div>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div
																							v-if="sales[activeSale].status=='incomplete'">
																							<v-select :items="quantity"
																							          v-model="item.quantity"
																							          outlined
																							          dense
																							          class="background text_field mr-3"
																							          item-value="amount"
																							          item-text="amount"
																							          @input="calculateTotal()"
																							>
																							</v-select>
																						</div>
																						<div v-else>
																							<v-select :items="quantity"
																							          disabled
																							          v-model="item.quantity"
																							          outlined
																							          dense
																							          class="background text_field mr-3"
																							          item-value="amount"
																							          item-text="amount"
																							          @input="calculateTotal()"
																							>
																							</v-select>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																			<v-flex xs12 class="mt-7">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<div class="ml-3"><b>Total
																							Price:</b></div>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div align="right" class="mr-3">
																							Kshs {{
																								(item.part.price * item.quantity) | formatNumber
																							}}
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>

																			<v-flex xs12 class="mt-3"
																			        v-if="sales[activeSale].status=='incomplete'">
																				<v-btn
																					depressed
																					@click="removeFromCart(index)"
																					rounded block
																					class="text-none mb-1 secondary white--text mt-2">
																					Remove Item
																					<v-icon right>mdi-delete-forever
																					</v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
															</template>

														</v-layout>
													</div>
												</v-flex>
											</v-layout>
										</div>
									</v-flex>

								</v-layout>
							</div>
						</v-flex>
						<v-flex xs12 md4 class="white">
							<div v-if="sales[activeSale].status=='incomplete'">
								<v-layout column class="mt-15 mx-15">
									<v-flex xs12 class="mt-4">
										<v-layout row wrap>
											<v-flex xs6>
												<div class="subtitle-1"><b>Total (Kshs)</b></div>
											</v-flex>
											<v-flex xs6>
												<div align="right">
													<div class="subtitle-1 green--text">
														<b>{{ sales[activeSale].totalAmount | formatNumber }}</b>
													</div>
												</div>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
								<v-divider class="mt-9 mx-15" v-if="sales[activeSale].items.length!=0"></v-divider>
								<v-layout column class="mt-10 mx-15" v-if="sales[activeSale].items.length!=0">
									<v-flex xs12>
										<div><b>CUSTOMER</b></div>
									</v-flex>
									<v-flex xs12 class="mt-1">
										<a>
											<v-card v-if="sales[activeSale].customer != 'student'" elevation="0"
											        class="background pa-10" style="border-radius: 25px;"
											        @click="customerAccount('student')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-account-school
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Student</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card v-else elevation="0" class="secondary pa-10"
											        style="border-radius: 25px;" @click="customerAccount('student')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-account-school
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">
																	Student
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<div v-if="sales[activeSale].customer == 'student'" class="mt-5 mx-3">
											<v-layout row wrap>
												<v-flex xs12>
													<div class="mt-4"><b>Acc No.</b></div>
												</v-flex>
												<v-flex xs12>
													<v-autocomplete v-model="sales[activeSale].student_id"
													                :loading="studentLoading" :items="students"
													                :search-input.sync="searchStudents" item-value="id"
													                item-text="account_no"
													                class="text_field background my-2" flat hide-no-data
													                hide-details outlined dense :rules="inputRules"
													                @change="pushStudent()">
														<template v-slot:selection="{ item }">
															{{ studentText(item) }}
														</template>
														<template v-slot:item="{ item }">
															{{ studentText(item) }}
														</template>
													</v-autocomplete>
												</v-flex>
											</v-layout>

										</div>
										<div v-if="sales[activeSale].customer == 'student'" class="mt-5">
											<v-radio-group v-model="sales[activeSale].user_id">
												<v-radio
													v-for="(user, index) in sales[activeSale].users"
													:key="index"
													:label="`${user.user.first_name} ${user.user.middle_name} ${user.user.last_name}`"
													:value="user.user.id"
													@change="updateUser()"
												></v-radio>
											</v-radio-group>
										</div>
										<a>
											<v-card v-if="sales[activeSale].customer != 'guest'" elevation="0"
											        class="background pa-10 mt-3" style="border-radius: 25px;"
											        @click="customerAccount('guest')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-account-question
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Guest</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card v-else elevation="0" class="secondary pa-10 mt-3"
											        style="border-radius: 25px;" @click="customerAccount('guest')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-account-question
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">Guest
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<div class="mt-6 mb-10 mx-3" v-if="sales[activeSale].customer == 'guest'">
											<v-layout row wrap>
												<v-flex xs12>
													<div><b>Narration</b></div>
												</v-flex>
												<v-flex xs12>
													<v-textarea class="background text_area" dense outlined
													            v-model="sales[activeSale].narration"
													            required></v-textarea>
												</v-flex>
											</v-layout>
										</div>
										<a>
											<v-card v-if="sales[activeSale].customer != 'strathmore-school'"
											        elevation="0" class="background pa-10 mt-3"
											        style="border-radius: 25px;" @click="strathmoreAccount()">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-office-building
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Strathmore School
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card v-else elevation="0" class="secondary pa-10 mt-3"
											        style="border-radius: 25px;"
											        @click="customerAccount('strathmore-school')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-office-building
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">
																	Strathmore School
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<div class="mt-6 mb-10 mx-3"
										     v-if="sales[activeSale].customer == 'strathmore-school'">
											<v-layout row wrap>
												<v-flex xs12>
													<div><b>Narration</b></div>
												</v-flex>
												<v-flex xs12>
													<v-textarea class="background text_area" dense outlined
													            v-model="sales[activeSale].narration"
													            required></v-textarea>
												</v-flex>
											</v-layout>
										</div>

									</v-flex>
									<v-flex xs12 class="mt-5">
										<div><b>PAYMENT METHOD</b></div>
									</v-flex>
									<v-flex xs12 class="mt-1">
										<a>
											<v-card
												v-if="sales[activeSale].paymentMethod != 'm-pesa' && sales[activeSale].customer != 'strathmore-school'"
												elevation="0" class="background pa-10" style="border-radius: 25px;"
												@click="paymentMethod('m-pesa')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-cellphone-text
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">M-Pesa</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card
												v-if="sales[activeSale].paymentMethod == 'm-pesa' && sales[activeSale].customer != 'strathmore-school'"
												elevation="0" class="secondary pa-10" style="border-radius: 25px;"
												@click="paymentMethod('m-pesa')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-cellphone-text
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">M-Pesa
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<div class="mt-6 mb-8 mx-3" v-if="sales[activeSale].paymentMethod == 'm-pesa'">
											<v-layout row wrap>
												<v-flex xs12>
													<div><b>Phone No.</b></div>
												</v-flex>
												<v-flex xs12>
													<v-text-field class="background text_field" dense outlined
													              v-model="sales[activeSale].phoneNumber"
													              required></v-text-field>
												</v-flex>
											</v-layout>
										</div>
										<a>
											<v-card
												v-if="sales[activeSale].paymentMethod != 'cash' && sales[activeSale].customer != 'strathmore-school'"
												elevation="0" class="background pa-10 mt-3" style="border-radius: 25px;"
												@click="paymentMethod('cash')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-cash</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Cash</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card
												v-if="sales[activeSale].paymentMethod == 'cash' && sales[activeSale].customer != 'strathmore-school'"
												elevation="0" class="secondary pa-10 mt-3" style="border-radius: 25px;"
												@click="paymentMethod('cash')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-cash</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">Cash
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<div class="mt-6 mb-8 mx-3" v-if="sales[activeSale].paymentMethod == 'cash'">
											<v-layout row wrap>
												<v-flex xs12 md8>
													<div class="mt-2"><b>Amount Tendered (Kshs)</b></div>
												</v-flex>
												<v-flex xs12 md4>
													<v-text-field @change="calculateBalance()" number
													              class="background text_field" dense outlined
													              v-model="sales[activeSale].amountTendered"
													              required></v-text-field>
												</v-flex>
											</v-layout>
											<v-layout row wrap>
												<v-flex xs12 md8>
													<div class="mt-2"><b>Balance</b></div>
												</v-flex>
												<v-flex xs12 md4>
													<div align="right" class="mt-2">
														Kshs {{ sales[activeSale].change }}
													</div>
												</v-flex>
											</v-layout>
										</div>
										<a>
											<v-card
												v-if="sales[activeSale].paymentMethod != 'credit' && sales[activeSale].customer == 'strathmore-school'"
												elevation="0" class="background pa-10 mt-3" style="border-radius: 25px;"
												@click="paymentMethod('cash')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-notebook-edit
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Credit</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card
												v-if="sales[activeSale].paymentMethod == 'credit' && sales[activeSale].customer == 'strathmore-school'"
												elevation="0" class="secondary pa-10 mt-3" style="border-radius: 25px;"
												@click="paymentMethod('cash')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-notebook-edit
														</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">Credit
																</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a>
										<!-- <a v-if="sales[activeSale].customer == 'student'">
											<v-card v-if="sales[activeSale].paymentMethod != 'wallet'" elevation="0" class="background pa-10 mt-3" style="border-radius: 25px;" @click="paymentMethod('wallet')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="black--text mt-1">mdi-wallet</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="font-weight-light mt-2">Wallet</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
											<v-card v-else elevation="0" class="secondary pa-10 mt-3" style="border-radius: 25px;" @click="paymentMethod('wallet')">
												<v-layout row wrap>
													<v-flex xs2>
														<v-icon large class="white--text mt-1">mdi-wallet</v-icon>
													</v-flex>
													<v-flex xs10>
														<v-layout column>
															<v-flex xs12>
																<div class="white--text font-weight-light mt-2">Wallet</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-card>
										</a> -->

									</v-flex>
									<v-flex xs12 class="mt-12">
										<v-btn v-if="sales.length != 0" depressed block large color="primary"
										       @click="checkout()" :loading="cartLoading" class="text-none mb-5">
											Complete
										</v-btn>
										<v-btn v-else disabled depressed block large color="primary"
										       @click="checkout()" :loading="cartLoading" class="text-none mb-5">
											Complete
										</v-btn>
									</v-flex>
								</v-layout>
							</div>
							<div v-if="sales[activeSale].status=='complete'" class="mt-2">
								<v-container fill-height fluid>
									<v-row align="center">
										<v-col class="text-center">
											<v-alert prominent type="success">
												<div>
													Sale Complete
												</div>
											</v-alert>
											<v-btn depressed block class="primary text-none" @click="printReceipt()"
											       :loading="receiptLoading">
												Print Receipt
												<v-icon right>mdi-receipt-text</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</div>
							<div v-if="sales[activeSale].status=='pending'" class="mt-2">
								<v-card-title> CONFIRM PAYMENT</v-card-title>
								<v-divider></v-divider>
								<v-card-text>
									<div>
										<div class="mx-3">
											<v-btn depressed block large color="primary" :disabled="!valid"
											       @click="confirmPayment()"
											       :loading="loading" class="text-none mb-10">
												Confirm Payment
											</v-btn>
										</div>
									</div>
								</v-card-text>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
	</div>
</template>
<style>
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
<script>
import axios from "axios"
import apiCall from "@/utils/api";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			loading: false,
			receiptLoading: false,
			studentLoading: false,
			valid: true,

			inputRules: [(v) => !!v || "Input is required"],

			message: "",
			color: "",
			delete: false,
			confirm: false,
			dialog: false,
			snackbar: false,
			cartLoading: false,

			items: [],
			students: [],
			searchParts: null,
			searchStudents: null,
			activeSale: 0,

			sales: [
				{
					items: [],
					user_type: null,
					part_id: null,
					part: null,
					subtotalAmount: 0,
					totalAmount: 0,
					customer: null,
					paymentMethod: null,
					student_id: null,
					student: null,
					user_id: null,
					users: null,
					phoneNumber: null,
					amountTendered: null,
					change: '0.00',
					wallet: '0.00',
					status: 'incomplete',
					narration: null,
					sale_id: null
				}
			],

			quantity: [
				{ amount: 1 },
				{ amount: 2 },
				{ amount: 3 },
				{ amount: 4 },
				{ amount: 5 },
				{ amount: 6 },
				{ amount: 7 },
				{ amount: 8 },
				{ amount: 9 },
				{ amount: 10 },
				{ amount: 11 },
				{ amount: 12 },
				{ amount: 13 },
				{ amount: 14 },
				{ amount: 15 },
				{ amount: 16 },
				{ amount: 17 },
				{ amount: 18 },
				{ amount: 19 },
				{ amount: 20 },
			],

			cartData: {
				id: null,
				quantity: null
			},

			formData: {
				phone_number: null,
			},
		};
	},
	mounted() {
		let self = this, barcode = '', timer = null;

		window.addEventListener('keypress', function (e) {
			if(Number.isInteger(Number(e.key))) barcode += e.key

			if (timer) clearTimeout(timer);

			timer = setTimeout(() => {
				if (barcode.length > 3) {
					self.handleBarcode(barcode);
				}

				barcode = '';
			}, 20);
		});
	},

	destroyed() {
		let barcode = '', timer = null;

		document.removeEventListener("keypress", function () {
			if (timer) clearTimeout(timer);

			timer = setTimeout(() => {
				if (barcode.length > 3) {
					this.handleBarcode(barcode);
				}

				barcode = '';
			}, 20);
		});
	},
	watch: {
		searchParts(val) {
			// We sent a keyup event and a timer is still active
			if(this.timer) {
				// So we clear and null it so it doesn't contact the api
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				// contact your endpoint here

				// Assuming your scanner can emit keystrokes
				// within 100 milliseconds from one another
				// otherwise increase this value as necessary
				val && val !== this.sales[this.activeSale].part_id && this.findPart(val);
			}, 100);
		},
		searchStudents(val) {
			val && val !== this.sales[this.activeSale].student_id && this.findStudent(val);
		},
	},
	methods: {
		...mapActions([
			"closeNav",

			"stopCartLoader",
			"filterCarts",
			"fetchCarts",
			"fetchCarts",
		]),
		printReceipt() {
			this.receiptLoading = true
			if (this.sales[this.activeSale].paymentMethod == 'cash') {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=cashReceipt&sale_id=" + this.sales[this.activeSale].sale_id,
					method: "GET",
					responseType: "blob",
				})
					.then((resp) => {
						this.receiptLoading = false
						this.downloadFile(resp, 'Receipt');
					})
					.catch(() => {
						this.receiptLoading = false
					});
			} else if (this.sales[this.activeSale].paymentMethod == 'credit') {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=creditReceipt&sale_id=" + this.sales[this.activeSale].sale_id,
					method: "GET",
					responseType: "blob",
				})
					.then((resp) => {
						this.receiptLoading = false
						this.downloadFile(resp, 'Receipt');
					})
					.catch(() => {
						this.receiptLoading = false
					});
			} else if (this.sales[this.activeSale].paymentMethod == 'm-pesa') {
				axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
				axios({
					url: this.path + "/api/pos?type=mpesaReceipt&sale_id=" + this.sales[this.activeSale].sale_id,
					method: "GET",
					responseType: "blob",
				})
					.then((resp) => {
						this.receiptLoading = false
						this.downloadFile(resp, 'Receipt');
					})
					.catch((error) => {
						this.receiptLoading = false
					});
			}
		},
		downloadFile(response, filename) {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			console.log('response.data', response.data)
			var newBlob = new Blob([response.data], { type: "application/pdf" });

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = filename + ".pdf";
			link.click();
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		},
		calculateBalance() {
			this.sales[this.activeSale].change = this.sales[this.activeSale].amountTendered - this.sales[this.activeSale].totalAmount
			this.activeSale = this.activeSale - 1
			this.activeSale = this.activeSale + 1
		},
		studentText(item) {
			return (item.first_name + ' ' + item.middle_name + ' ' + item.last_name + ' - ' + item.account_no + '/' + item.admission_no)
		},
		closeSale(index) {
			this.activeSale = index - 1
			this.sales.splice(index, 1)
		},
		addSale() {
			let obj = {
				items: [],
				user_type: null,
				user_id: null,
				status: 'incomplete'
			}
			this.sales.push(obj)
			this.activeSale = (this.sales.length - 1)
		},
		pushPart() {
			let activePart;
			if (this.items.length === 1) {
				activePart = this.items.find((part) => part.id == this.sales[this.activeSale].part_id)
			} else {
				activePart = this.items[0]
			}
			console.log(this.items, this.items.length === 1, activePart)

			let obj = {
				part: activePart,
				part_id: activePart.id,
				quantity: 1
			}

			let partCheck = this.sales[this.activeSale].items.find((part) => part.part_id == activePart.id)

			if (!partCheck) {
				this.sales[this.activeSale].items.push(obj)
			} else {
				partCheck.quantity += 1
			}


			this.sales[this.activeSale].part = activePart

			this.calculateTotal()

			this.sales[this.activeSale].part_id = null
			this.items = []
		},
		pushStudent() {
			let activeStudent = this.students.find((student) => student.id == this.sales[this.activeSale].student_id)

			this.sales[this.activeSale].student = activeStudent
			this.sales[this.activeSale].student_id = activeStudent.id,
				this.sales[this.activeSale].users = activeStudent.user_students
		},
		updateUser() {
			this.$nextTick(() => {
				let activeUser = this.sales[this.activeSale].users.find((user) => user.user.id == this.sales[this.activeSale].user_id)

				this.sales[this.activeSale].phoneNumber = activeUser.user.phone
			});
		},
		customerAccount(customer) {
			this.sales[this.activeSale].customer = customer
			this.activeSale = this.activeSale - 1
			this.activeSale = this.activeSale + 1
		},
		strathmoreAccount() {
			this.sales[this.activeSale].customer = 'strathmore-school'
			this.activeSale = this.activeSale - 1
			this.activeSale = this.activeSale + 1

			this.paymentMethod('credit')
		},
		paymentMethod(method) {
			this.sales[this.activeSale].paymentMethod = method
			this.activeSale = this.activeSale - 1
			this.activeSale = this.activeSale + 1
		},
		findPart() {
			this.loading = true;

			apiCall({ url: "/api/part?type=search&search=" + this.searchParts, method: "GET", })
				.then((resp) => {
					this.items = resp.data;
					this.loading = false;

					this.handleBarcode(this.searchParts)
				})
				.catch(() => this.loading = false)		},
		handleBarcode(barcode) {
			this.message = `Item added: Barcode - ${barcode}`;
			this.color = "success";
			this.snackbar = true;

			apiCall({
				url: "/api/part?type=barcode&barcode=" + barcode,
				method: "GET",
			})
				.then((resp) => {
					this.searchParts = null

					let obj = {
						part: resp,
						part_id: resp.id,
						quantity: 1
					}

					let partCheck = this.sales[this.activeSale].items.find((part) => part.part_id == resp.id)

					if (!partCheck) {
						this.sales[this.activeSale].items.push(obj)
					} else {
						partCheck.quantity += 1
					}


					this.sales[this.activeSale].part = resp

					this.calculateTotal()
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				});
		},
		findStudent(v2022021706547
		) {
			this.studentLoading = true;

			apiCall({
				url: "/api/student?type=search&search=" + this.searchStudents,
				method: "GET",
			})
				.then((resp) => {
					this.students = resp.data;
					this.studentLoading = false;
				})
				.catch(() => {
					this.studentLoading = false;
				});
		},
		checkout() {
			if (this.sales[this.activeSale].customer == null) {
				this.message = "Please Pick a Customer Type";
				this.color = "error";
				this.snackbar = true;
			} else if (this.sales[this.activeSale].customer == 'student' && this.sales[this.activeSale].student_id == null) {
				this.message = "Please Pick a Student";
				this.color = "error";
				this.snackbar = true;
			} else if (this.sales[this.activeSale].customer == 'student' && this.sales[this.activeSale].user_id == null) {
				this.message = "Please Pick a Parent/Guardian";
				this.color = "error";
				this.snackbar = true;
			} else if (this.sales[this.activeSale].paymentMethod == null) {
				this.message = "Please Pick a Payment Method";
				this.color = "error";
				this.snackbar = true;
			} else if (this.sales[this.activeSale].paymentMethod == 'm-pesa' && this.sales[this.activeSale].phoneNumber == null) {
				this.message = "Please enter a valid phone number";
				this.color = "error";
				this.snackbar = true;
			} else {
				if (this.sales[this.activeSale].paymentMethod == 'm-pesa') {
					if (this.sales[this.activeSale].phoneNumber.startsWith("07")) {
						this.sales[this.activeSale].phoneNumber = '254' + this.sales[this.activeSale].phoneNumber.slice(1)
					} else if (this.sales[this.activeSale].phoneNumber.startsWith("7")) {
						this.sales[this.activeSale].phoneNumber = '254' + this.sales[this.activeSale].phoneNumber
					} else if (this.sales[this.activeSale].phoneNumber.startsWith("01")) {
						this.sales[this.activeSale].phoneNumber = '254' + this.sales[this.activeSale].phoneNumber.slice(1)
					} else if (this.sales[this.activeSale].phoneNumber.startsWith("1")) {
						this.sales[this.activeSale].phoneNumber = '254' + this.sales[this.activeSale].phoneNumber
					}
				}

				this.cartLoading = true
				apiCall({
					url: "/api/pos",
					method: "POST",
					data: this.sales[this.activeSale]
				}).then((resp) => {
					this.cartLoading = false
					this.sales[this.activeSale].sale_id = resp.sale_id
					if (resp.status == 'complete') {
						this.sales[this.activeSale].status = 'complete'
					} else if (resp.status == 'pending') {
						this.sales[this.activeSale].status = 'pending'
						this.sales[this.activeSale].sale_code = resp.sale_code
					} else {
						this.message = resp.message;
						this.color = "error";
						this.snackbar = true;
					}

				})
					.catch((error) => {
						this.cartLoading = false
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;

						console.log(error.response);
					});
			}

		},
		confirmPayment() {
			this.loading = true
			apiCall({
				url: "/api/mpesa-confirm-payment",
				data: this.sales[this.activeSale],
				method: "POST",
			})
				.then((resp) => {
					if (resp.status == 'error') {
						this.message = "Payment Not Completed Yet";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
						this.confirm = false;

						this.view = "confirm";
					} else {
						this.message = "Payment Confirmed";
						this.color = "success";
						this.loading = false;
						this.snackbar = true;
						this.confirm = false;

						this.sales[this.activeSale].status = 'complete'
					}
				})
				.catch(() => {
					this.loading = false;
					this.confirm = false;
				});
		},
		calculateTotal(resp) {
			this.sales[this.activeSale].subtotalAmount = 0
			this.sales[this.activeSale].totalAmount = 0
			for (var i = 0; i < this.sales[this.activeSale].items.length; i++) {
				this.sales[this.activeSale].subtotalAmount += this.sales[this.activeSale].items[i].quantity * parseInt(this.sales[this.activeSale].items[i].part.price);
			}

			this.sales[this.activeSale].totalAmount = this.sales[this.activeSale].subtotalAmount

			this.calculateBalance()
		},

		removeFromCart(index) {
			this.sales[this.activeSale].items.splice(index, 1);
			this.calculateTotal()
		},
	},
	computed: {
		...mapGetters(["carts", "cartPagination", "cartLoader", "getProfile"]),
		length: function () {
			return Math.ceil(
				this.cartPagination.total / this.cartPagination.per_page
			);
		},
	},
};
</script>
  