<template>
	<div class="openSalePartStocks" v-if="$can('sale_refund_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<div class="pa-5">
			<v-card elevation="0" class="mt-5" v-if="view == 'open' || view == 'closed'">
				<v-tabs show-arrows v-model="viewTab">
					<v-tabs-slider color="primary"></v-tabs-slider>
					<v-tab @click="changeCurrentView('open')">
						<div class="text-none primary--text">Open Refund Requests ({{
								openSalePartStockPagination.total
							}})
						</div>
					</v-tab>
					<v-tab @click="changeCurrentView('closed')">
						<div class="text-none primary--text">Closed Refund Requests ({{
								closedSalePartStockPagination.total
							}})
						</div>
					</v-tab>

				</v-tabs>
			</v-card>

			<v-divider class="mt-5"></v-divider>

			<div v-if="view == 'open'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="openSalePartStockLoader" height="1" indeterminate
												 color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												openSalePartStockPagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
															  append-icon="mdi-undo-variant" @click:append="resetOpenSaleSearch()"
															  v-on:keyup.enter="searchOpenSale" v-model="openSaleSearchTerm"
															  @input="enableOpenSaleSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchOpenSale" :loading="searchOpenSaleLoader"
													 :disabled="openSaleSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="openSalePartStocks?.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Open Refund Requests Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Parent/<small>Student</small></th>
													<th class="text-left text--text">Item</th>
													<th class="text-right text--text">Quantity</th>
													<th class="text-right text--text">Total Amount</th>
													<th class="text-left text--text">Initiator</th>
													<th class="text-left text--text">Reason</th>
													<th class="text-left text--text">Creation Date</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in openSalePartStocks" :key="item.id">
													<td>
														<div>{{ item.sale_part_stock.sale_part.sale.user?.full_name }}</div>
														<small>
															<span v-if="item.sale_part_stock.sale_part.sale.sale_student!=null">
																{{
																	item.sale_part_stock.sale_part.sale.sale_student.student?.full_name
																}}
															</span>
															<span v-else>
																N/A
															</span>
														</small>
													</td>
													<td>
														{{ item.sale_part_stock.sale_part.part.name }}
													</td>
													<td>
														<div align="right">{{ item.quantity }}</div>
													</td>
													<td>
														<div align="right">
															{{ item.sale_part_stock.sale_part?.sub_total }}
														</div>
													</td>
													<td>{{ item.sale_part_stock.initiator?.full_name }}</td>
													<td :title="item.reason ?? 'No Reason'">
														<span style="max-width:20vw;overflow:hidden;display:-webkit-box;line-clamp:2;-webkit-line-clamp:2;-webkit-box-orient:vertical;">
															{{ item.reason ?? 'No Reason' }}
														</span>
													</td>
													<td>
														<div>{{
																item.sale_part_stock.sale_part.sale.created_at | moment("DD/MM/YYYY")
															}}
														</div>
														<small>{{
																item.sale_part_stock.sale_part.sale.created_at | moment("hh:mm A")
															}}</small>
													</td>
													<td>
														<div align="right">
															<v-tooltip top v-if="$can('sale_refund_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text"
																			 @click="showSale(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Sale</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('log_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)"
																			 v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-script-text</v-icon>
																	</v-btn>
																</template>
																<span>Log</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(salePartStock, index) in openSalePartStocks">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title title--text ml-3">
																				<b>{{
																						salePartStock.sale_part_stock.sale_part.sale.sale_no
																					}}</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>

																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sale Code</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.sale_code
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Parent/Guardian</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.user?.full_name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Student</b>
																			</div>
																			<div>
																				<div v-if="salePartStock.sale_part_stock.sale_part.sale.sale_student!=null">
																					{{
																						salePartStock.sale_part_stock.sale_part.sale.sale_student.student?.full_name
																					}}
																				</div>
																				<div v-else>
																					N/A
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.part.name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Unit Price (Kshs)</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part?.unit_price
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Quantity</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part?.quantity
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total (Kshs)</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.sale_part?.sub_total }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Initiator</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.initiator?.full_name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showSale(salePartStock)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12 v-if="$can('log_view')">
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="purple text-none white--text"
																								 @click="viewLog(sale)" v-bind="attrs"
																								 v-on="on"> Log
																							<v-icon small> mdi-script-text</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="openSaleLength != 0" :length="openSaleLength" total-visible="10"
												  v-model="openSalePartStockPagination.current_page" @input="changeOpenSalePage()"
												  circle>
								</v-pagination>
							</div>
						</v-flex>

					</v-layout>


				</v-layout>
			</div>
			<div v-if="view == 'closed'">
				<v-layout column>
					<v-card elevation="0" class="mt-2">
						<v-progress-linear v-if="closedSalePartStockLoader" height="1" indeterminate
												 color="primary"></v-progress-linear>
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<div v-if="length != 0" align="left" class="mt-1">
											<b class="display-1 primary--text">Total: </b>{{
												closedSalePartStockPagination.total | formatNumber
											}}
										</div>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field background" outlined dense rounded label="Search"
															  append-icon="mdi-undo-variant" @click:append="resetClosedSaleSearch()"
															  v-on:keyup.enter="searchClosedSale" v-model="closedSaleSearchTerm"
															  @input="enableClosedSaleSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1" align="right">
											<v-btn class="button mx-1" icon @click="searchClosedSale"
													 :loading="searchClosedSaleLoader"
													 :disabled="closedSaleSearchButtonDisabled">
												<v-icon class="secondary--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<v-divider class="mt-9 mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="closedSalePartStocks?.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Closed Refund Requests Found</v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
												<tr class="accent">
													<th class="text-left text--text">Sale No</th>
													<th class="text-left text--text">Sale Code</th>
													<th class="text-left text--text">Parent/Guardian</th>
													<th class="text-left text--text">Student</th>
													<th class="text-left text--text">Item</th>
													<th class="text-right text--text">Quantity</th>
													<th class="text-right text--text">Total (Kshs)</th>
													<th class="text-left text--text">Initiator</th>
													<th class="text-left text--text">Reviewer</th>
													<th class="text-left text--text">Creation Date</th>
													<th class="text-right text--text">Actions</th>
												</tr>
												</thead>
												<tbody>
												<tr v-for="item in closedSalePartStocks" :key="item.id">
													<td>
														{{ item.sale_part_stock.sale_part.sale.sale_no }}
													</td>
													<td>
														{{ item.sale_part_stock.sale_part.sale.sale_code }}
													</td>
													<td>
														{{ item.sale_part_stock.sale_part.sale.user?.full_name }}
													</td>
													<td>
														<div v-if="item.sale_part_stock.sale_part.sale.sale_student!=null">
															{{ item.sale_part_stock.sale_part.sale.sale_student.student?.full_name }}
														</div>
														<div v-else>
															N/A
														</div>
													</td>
													<td>
														{{ item.sale_part_stock.sale_part.part.name }}
													</td>
													<td>
														<div align="right">
															{{ item.quantity }}
														</div>
													</td>
													<td>
														<div align="right">
															{{ item.sale_part_stock.sale_part?.sub_total }}
														</div>
													</td>
													<td>
														{{ item.sale_part_stock.initiator?.full_name }}
													</td>
													<td>
														{{ item.sale_part_stock.approver?.full_name }}
													</td>
													<td>
														{{
															item.sale_part_stock.sale_part.sale.created_at | moment("DD/MM/YYYY - hh:mm a")
														}}
													</td>
													<td>
														<div align="right">
															<v-tooltip top v-if="$can('sale_refund_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 universal--text"
																			 @click="showSale(item)">
																		<v-icon small> mdi-eye</v-icon>
																	</v-btn>
																</template>
																<span>View Sale</span>
															</v-tooltip>
															<v-tooltip top v-if="$can('log_view')">
																<template v-slot:activator="{ on, attrs }">
																	<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)"
																			 v-bind="attrs"
																			 v-on="on">
																		<v-icon small> mdi-script-text</v-icon>
																	</v-btn>
																</template>
																<span>Log</span>
															</v-tooltip>
														</div>
													</td>
												</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(salePartStock, index) in closedSalePartStocks">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0" style="border-radius: 25px;" outlined>
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title title--text ml-3">
																				<b>{{
																						salePartStock.sale_part_stock.sale_part.sale.sale_no
																					}}</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>

																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Sale Code</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.sale_code
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Parent/Guardian</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.user?.full_name
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Student</b>
																			</div>
																			<div>
																				<div v-if="salePartStock.sale_part_stock.sale_part.sale.sale_student!=null">
																					{{
																						salePartStock.sale_part_stock.sale_part.sale.sale_student.student?.full_name
																					}}
																				</div>
																				<div v-else>
																					N/A
																				</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Item</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.sale_part.part.name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Unit Price (Kshs)</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.sale_part?.unit_price }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Quantity</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.sale_part?.quantity }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Total (Kshs)</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part?.sub_total
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Initiator</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.initiator?.full_name }}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Reviewer</b>
																			</div>
																			<div>
																				{{ salePartStock.sale_part_stock.approver?.full_name }}
																			</div>
																		</v-flex>


																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Creation Date</b>
																			</div>
																			<div>
																				{{
																					salePartStock.sale_part_stock.sale_part.sale.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn rounded depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showSale(salePartStock)">View
																							<v-icon right> mdi-eye</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12 v-if="$can('log_view')">
																					<div class="pa-1">
																						<v-btn rounded block depressed
																								 class="purple text-none white--text"
																								 @click="viewLog(sale)" v-bind="attrs"
																								 v-on="on"> Log
																							<v-icon small> mdi-script-text</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>
					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="closedSaleLength != 0" :length="closedSaleLength" total-visible="10"
												  v-model="closedSalePartStockPagination.current_page"
												  @input="changeClosedSalePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
					</v-layout>
				</v-layout>
			</div>
			<div v-if="view == 'show'">
				<v-card outlined class="mt-2">
					<v-card-title class="accent text--text">
						{{ assignedRefund.sale_part_stock.sale_part.sale.sale_no }} -
						{{ assignedRefund.sale_part_stock.sale_part.sale.user?.full_name }}
						<v-spacer></v-spacer>
						<v-btn v-if="prevView=='open'" icon @click="changeView('open')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
						<v-btn v-if="prevView=='closed'" icon @click="changeView('closed')">
							<v-icon class="text--text"> mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<div class="pa-3">
							<v-tabs show-arrows v-model="saleTab">
								<v-tabs-slider color="primary"></v-tabs-slider>

								<v-tab @click="activeTab = 'details'">
									<div class="text-none primary--text">Details</div>
								</v-tab>
								<v-tab @click="activeTab = 'saleItems'">
									<div class="text-none primary--text">
										Part
									</div>
								</v-tab>
							</v-tabs>
							<div v-if="activeTab == 'details'" class="mx-3 mt-7">
								<v-layout row wrap>
									<v-flex xs12 md6>
										<v-layout column>

											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Parent/Guardian Details:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Name:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedRefund.sale_part_stock.sale_part.sale.user?.full_name }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Email:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedRefund.sale_part_stock.sale_part.sale.user?.email }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Phone #:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{ assignedRefund.sale_part_stock.sale_part.sale.user?.phone }}
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

										<v-layout column class="mt-10"
													 v-if="assignedRefund.sale_part_stock.sale_part.sale.sale_student != null">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Student Details:</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Name:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.sale_student?.student?.full_name
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Email:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.sale_student.student?.email
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Phone #:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.sale_student.student?.phone
														}}
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

									</v-flex>
									<v-flex xs12 md6>
										<v-layout column>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Sale Details</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Sale Code:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.sale_code
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Sale No:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.sale_no
														}}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Total (Kshs):</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div align="left">
															{{
																assignedRefund.total
															}}
														</div>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Creation Date:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														{{
															assignedRefund.sale_part_stock.sale_part.sale.created_at
																	| moment("DD/MM/YYYY - hh:mm a")
														}}
													</v-flex>
												</v-layout>
											</v-flex>

										</v-layout>

										<v-layout column class="mt-10">
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs11>
														<div><b>Payment Details</b></div>
													</v-flex>
													<v-flex xs1>

													</v-flex>
												</v-layout>

											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Ref Code:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.cash_payment!=null">
															{{
																assignedRefund.sale_part_stock.sale_part.sale.cash_payment.cash_no
															}}
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.credit!=null">
															{{ assignedRefund.sale_part_stock.sale_part.sale.credit.credit_no }}
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.mpesa!=null">
															{{
																assignedRefund.sale_part_stock.sale_part.sale.mpesa.mpesa_callback_metadata.MpesaReceiptNumber
															}}
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.sale_wallet_logs?.length != 0">
															<template
																	v-for="(sale_wallet_log) in assignedRefund.sale_part_stock.sale_part.sale.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }}
																</div>
															</template>
														</div>

													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md2>
														<div><b>Amount:</b></div>
													</v-flex>
													<v-flex xs12 md10>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.cash_payment!=null">
															{{
																assignedRefund.sale_part_stock.sale_part.sale.cash_payment.total
															}}
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.credit!=null">
															Credit
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.mpesa!=null">
															{{
																assignedRefund.sale_part_stock.sale_part.sale.mpesa.mpesa_callback_metadata.Amount
															}}
														</div>
														<div v-if="assignedRefund.sale_part_stock.sale_part.sale.sale_wallet_logs?.length != 0">
															<template
																	v-for="(sale_wallet_log) in assignedRefund.sale_part_stock.sale_part.sale.sale_wallet_logs">
																<div>
																	{{ sale_wallet_log.wallet_log.transaction_number }} -
																	{{ sale_wallet_log.wallet_log.amount }}
																</div>
															</template>
														</div>

													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
							</div>
							<div v-if="activeTab == 'saleItems'" class="mt-2">
								<v-layout column>
									<v-flex xs12>
										<div class="my-5">
											<v-card elevation="0">
												<v-layout row wrap>
													<v-flex xs12 md4>
														<v-img
																v-if="assignedRefund.sale_part_stock.sale_part?.part.image == null"
																class="background"
																contain
																style="border-radius: 25px;" max-height="180"
																src="img/book-silhouette-vector-14.png">

														</v-img>
														<v-img max-height="180" contain v-else
																 :src="path + '/storage/part_pictures/' + assignedRefund.sale_part_stock.sale_part?.part.image"
																 style="border-radius: 25px;" class="background">

														</v-img>
													</v-flex>
													<v-flex xs12 md8>
														<div class="mx-10">
															<v-layout column>
																<v-flex xs12 class="mt-1">
																	<div class="ml-2 primary--text">
																		{{ assignedRefund.sale_part_stock.sale_part.part.name }}
																	</div>
																</v-flex>

																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout row wrap>
																			<v-flex xs3>
																				<b> Refund Reason:</b>
																			</v-flex>
																			<v-flex xs9>
																				<div v-if="assignedRefund.reason">
																					<b>{{
																							assignedRefund.reason?.toUpperCase()
																						}}</b>
																				</div>
																				<div v-else>No Reason</div>
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>

																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout row wrap>
																			<v-flex xs3>
																				<b>Serial No:</b>
																			</v-flex>
																			<v-flex xs9>
																				{{
																					assignedRefund.sale_part_stock.sale_part?.part.serial_no
																				}}
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout row wrap>
																			<v-flex xs3>
																				<b>Quantity Purchased:</b>
																			</v-flex>
																			<v-flex xs9>
																				{{
																					assignedRefund.sale_part_stock.sale_part?.quantity
																				}}
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout row wrap>
																			<v-flex xs3>
																				<b>Unit Price(Kshs):</b>
																			</v-flex>
																			<v-flex xs9>
																				{{
																					assignedRefund.sale_part_stock.sale_part?.unit_price
																				}}
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout row wrap>
																			<v-flex xs3>
																				<b>Sub Total:</b>
																			</v-flex>
																			<v-flex xs9>
																				{{
																					assignedRefund.sale_part_stock.sale_part?.sub_total
																				}}
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
															</v-layout>


															<v-layout column class="mt-10">
																<v-flex xs12 class="mt-1">
																	<div class="ml-2 primary--text mb-3">
																		<b>Issued Stock</b>
																	</div>
																</v-flex>

																<v-flex xs12>
																	<div class="ml-5 mt-5">
																		<v-layout column>
																			<v-flex xs12 class="mb-16">
																				<v-layout row wrap>
																					<v-flex xs12 md4>
																						<b>Stock No:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{
																							assignedRefund.sale_part_stock.stock.stock_no
																						}}
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Invoice No:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{
																							assignedRefund.sale_part_stock.stock.stock_no
																						}}
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Supplier:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{
																							assignedRefund.sale_part_stock.stock.supplier.name
																						}}
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Selling Price:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{
																							assignedRefund.sale_part_stock.stock.selling_price
																						}}
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Quantity:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						{{ assignedRefund.quantity }}
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Status:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div v-if="assignedRefund.sale_part_stock.stock.status==0">
																							Pending Issue
																						</div>
																						<div v-if="assignedRefund.sale_part_stock.stock.status==1">
																							Issued
																						</div>
																						<div v-if="assignedRefund.sale_part_stock.stock.status==2">
																							Returned & Refunded
																						</div>
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md4>
																						<b>Refund Status:</b>
																					</v-flex>
																					<v-flex xs12 md8>
																						<div v-if="assignedRefund.sale_part_stock.status==2"
																							  class="green--text">
																							Approved
																						</div>
																						<div v-if="assignedRefund.sale_part_stock.status==3"
																							  class="red--text">
																							Rejected
																						</div>
																					</v-flex>
																				</v-layout>
																				<v-layout row wrap class="mt-5">
																					<v-flex xs12 md6>
																						<div class="mx-1 my-1">
																							<v-btn
																									v-if="$can('sale_refund_approve') && (assignedRefund.sale_part_stock.status==0 || assignedRefund.sale_part_stock.status==1)"
																									depressed block
																									class="green text-none white--text"
																									@click="approveRefund(assignedRefund)"
																									:loading="stockLoading && refundPartStockIndex == assignedRefund.id">
																								Approve Refund
																								<v-icon right>
																									mdi-check-all
																								</v-icon>
																							</v-btn>
																							<v-btn
																									v-if="$can('sale_refund_approve') && (assignedRefund.sale_part_stock.status==2 || assignedRefund.sale_part_stock.status==3)"
																									depressed block disabled
																									class="green text-none white--text">
																								Approve Refund
																								<v-icon right>
																									mdi-check-all
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12 md6>
																						<div class="mx-1 my-1">
																							<v-btn
																									v-if="$can('sale_refund_decline') && (assignedRefund.sale_part_stock.status==0 || assignedRefund.sale_part_stock.status==1)"
																									depressed block
																									class="secondary text-none white--text"
																									@click="rejectRefund(assignedRefund)"
																									:loading="rejectRefundStockLoading && refundPartStockIndex == assignedRefund.id">
																								Reject Refund
																								<v-icon right>
																									mdi-cancel
																								</v-icon>
																							</v-btn>
																							<v-btn
																									v-if="$can('sale_refund_decline') && (assignedRefund.sale_part_stock.status==2 || assignedRefund.sale_part_stock.status==3)"
																									depressed block disabled
																									class="secondary text-none white--text">
																								Reject Refund
																								<v-icon right>
																									mdi-cancel
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>


																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</div>
																</v-flex>
															</v-layout>
														</div>

													</v-flex>
												</v-layout>
											</v-card>

											<v-divider class="mt-10"></v-divider>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</div>
		</div>
		<logData v-if="logDataModel" v-model="logDataModel"/>
	</div>
</template>
<style>
.ck-editor__editable {
	min-height: 200px;
}
</style>
<script>
import apiCall from "@/utils/api";
import {mapActions, mapGetters} from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			view: "open",
			prevView: "open",
			loading: false,
			activateLoading: false,
			deactivateLoading: false,
			broadcastLoading: false,
			stockLoading: false,
			rejectRefundStockLoading: false,
			saleView: "default",

			activeTab: 'details',
			saleTab: null,

			editor: ClassicEditor,
			editorConfig: {},

			menu1: false,
			menu2: false,
			allowedStep: (m) => m % 15 === 0,
			valid: true,
			startDatePicker: false,
			endDatePicker: false,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			saleIndex: null,
			salePartStockIndex: null,
			refundPartStockIndex: null,
			snackbar: false,
			inputRules: [(v) => !!v || "Input is required"],

			coverImageDialog: false,
			imageDialog: false,
			imageLoading: false,
			fileRules: [(v) => !!v || "Kindly selecta a File"],
			imageFile: null,
			imageValid: true,

			openSaleSearchTerm: null,
			closedSaleSearchTerm: null,
			searchOpenSaleLoader: false,
			searchClosedSaleLoader: false,
			openSaleSearchButtonDisabled: false,
			closedSaleSearchButtonDisabled: false,
			activeSaleOwnerTab: 'organisation',
			filteredOpenSales: false,
			filteredClosedSales: false,

			viewTab: null,
			newSale: {
				id: null,
				sale_type_id: null,
				title: null,
				brief: null,
				description: null,
				start_date: null,
				start_time: null,
				end_date: null,
				end_time: null,
				entry_cost: null,
				location: null,
				longitude: null,
				latitude: null,
				interests: [],
				sectors: [],
				sdgs: [],
				institutions: [],
				organisations: []
			},
			newSalePartStock: {
				id: null,
			},
			assignedRefund: null,
			assignedResponse: null,
			formData: null,
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{name: "January"},
				{name: "February"},
				{name: "March"},
				{name: "April"},
				{name: "May"},
				{name: "June"},
				{name: "July"},
				{name: "August"},
				{name: "September"},
				{name: "October"},
				{name: "November"},
				{name: "December"},
			],

			logData: {
				message: null,
				model: null,
			},
		};
	},
	created() {
		if (this.$can("sale_refund_view")) {
			this.startOpenSalePartStockLoader();
			this.startClosedSalePartStockLoader();
			this.fetchOpenSalePartStocks(this.openSalePartStockPagination.current_page);
			this.fetchClosedSalePartStocks(this.closedSalePartStockPagination.current_page);
			this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchOpenSalePartStocks",
			"fetchClosedSalePartStocks",
			"startOpenSalePartStockLoader",
			"startClosedSalePartStockLoader",
			"stopOpenSalePartStockLoader",
			"stopClosedSalePartStockLoader",
			"filterOpenSalePartStocks",
			"filterClosedSalePartStocks",

			"filterLogData",
			"openLogDataModel",
		]),
		changeCurrentView(view) {
			this.prevView = view
			this.changeView(view)
		},
		enableOpenSaleSearch() {
			this.openSaleSearchButtonDisabled = false;
		},
		enableClosedSaleSearch() {
			this.closedSaleSearchButtonDisabled = false;
		},
		closeStartDate() {
			this.startDatePicker = false;
		},
		closeEndDate() {
			this.endDatePicker = false;
		},
		removeInstitution(item) {
			this.newSale.institutions.splice(this.newSale.institutions.indexOf(item), 1)
		},
		removeOrganisation(item) {
			this.newSale.organisations.splice(this.newSale.organisations.indexOf(item), 1)
		},

		changeSaleOwnerTab(tab) {
			this.newSale.institutions = [],
					this.newSale.organisations = [],

					this.activeSaleOwnerTab = tab
		},
		resetOpenSaleSearch() {
			this.openSaleSearchTerm = null;
			this.filteredClosedSales = true;
			this.startOpenSalePartStockLoader();
			this.fetchOpenSalePartStocks(1);
		},
		resetClosedSaleSearch() {
			this.closedSaleSearchTerm = null;
			this.filteredClosedSales = true;
			this.stopClosedSalePartStockLoader();
			this.fetchClosedSalePartStocks(1);
		},
		showSale(salePartStock) {
			this.assignedRefund = salePartStock;
			this.changeView("show");
		},

		searchOpenSale() {
			if (this.openSaleSearchTerm == null) {
				this.openSaleSearchButtonDisabled = true;
			} else {
				this.searchOpenSaleLoader = true;
				this.openSaleSearchButtonDisabled = false;
				this.stopOpenSalePartStockLoader();
				apiCall({
					url: "/api/sale-part-stock-refunds?type=open&typeB=search&search=" + this.openSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenSalePartStocks(resp);
							this.searchOpenSaleLoader = false;
							this.stopOpenSalePartStockLoader();
							this.filteredOpenSales = true;
						})
						.catch((error) => {
							console.log(error.response);
							this.searchOpenSaleLoader = false;
							this.stopOpenSalePartStockLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
						});
			}
		},
		searchClosedSale() {
			if (this.closedSaleSearchTerm == null) {
				this.closedSaleSearchButtonDisabled = true;
			} else {
				this.searchClosedSaleLoader = true;
				this.closedSaleSearchButtonDisabled = false;
				this.stopClosedSalePartStockLoader();
				apiCall({
					url: "/api/sale-part-stock-refunds?type=closed&typeB=search&search=" + this.closedSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedSalePartStocks(resp);
							this.searchClosedSaleLoader = false;
							this.stopClosedSalePartStockLoader();
							this.filteredOpenSales = true;
						})
						.catch((error) => {
							this.searchClosedSaleLoader = false;
							this.stopClosedSalePartStockLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							reje
							this.snackbar = true;
							console.log(error);
						});
			}
		},
		changeView(view) {
			if (view == "open") {
				this.newSale.id = null;
				this.newSale.sale_type_id = null
				this.newSale.title = null
				this.newSale.brief = null
				this.newSale.description = null
				this.newSale.start_date = null
				this.newSale.start_time = null
				this.newSale.end_date = null
				this.newSale.end_time = null
				this.newSale.entry_cost = null
				this.newSale.location = null
				this.newSale.longitude = null
				this.newSale.latitude = null
				this.newSale.interests = []
				this.newSale.sectors = []
				this.newSale.sdgs = []
				this.newSale.institutions = []
				this.newSale.organisations = []
			} else if (view == "show") {

			}
			this.view = view;
		},
		changeOpenSalePage() {
			this.startOpenSalePartStockLoader();
			if (this.filteredOpenSales == true) {
				this.openSaleSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/sale-part-stock-refunds?type=open&page=" +
							this.openSalePartStockPagination.current_page +
							"&typeB=search&search=" +
							this.openSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterOpenSalePartStocks(resp);
							this.stopOpenSalePartStockLoader();
						})
						.catch((error) => {
							this.stopOpenSalePartStockLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchOpenSalePartStocks(this.openSalePartStockPagination.current_page);
			}
		},
		changeClosedSalePage() {
			this.startClosedSalePartStockLoader();
			if (this.filteredClosedSales == true) {
				this.closedSaleSearchButtonDisabled = false;
				apiCall({
					url:
							"/api/sale-part-stock-refunds?type=closed&page=" +
							this.closedSalePartStockPagination.current_page +
							"&typeB=search&search=" +
							this.closedSaleSearchTerm,
					method: "GET",
				})
						.then((resp) => {
							this.filterClosedSalePartStocks(resp);
							this.stopClosedSalePartStockLoader();
						})
						.catch((error) => {
							this.stopClosedSalePartStockLoader();
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							console.log(error);
						});
			} else {
				this.fetchClosedSalePartStocks(this.closedSalePartStockPagination.current_page);
			}
		},

		approveRefund(item) {
			confirm("Are You Sure You Want to Refund Stock Item?") &&
			(this.activate = true);
			if (this.activate) {
				this.stockLoading = true;
				this.refundPartStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: `/api/sale-part-stock-refunds/${item.id}/approve`,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Stock Item Refunded Successfully";
							this.color = "success";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							this.assignedRefund = resp
							this.fetchOpenSalePartStocks(this.openSalePartStockPagination.current_page);
							this.fetchClosedSalePartStocks(this.openSalePartStockPagination.current_page);
              this.changeView('open');
						})
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.stockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							console.log(error.response);
              this.changeView('open');
            });
			}
		},

		rejectRefund(item) {
			confirm("Are You Sure You Want to Reject Refund Request?") &&
			(this.activate = true);
			if (this.activate) {
				this.rejectRefundStockLoading = true;
				this.refundPartStockIndex = item.id;
				this.newSalePartStock.id = item.id;
				apiCall({
					url: `/api/sale-part-stock-refunds/${item.id}/reject`,
					method: "POST",
				})
						.then((resp) => {
							this.message = "Stock Item Rejected Successfully";
							this.color = "success";
							this.snackbar = true;
							this.rejectRefundStockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							this.assignedRefund = resp
							this.fetchOpenSalePartStocks(this.openSalePartStockPagination.current_page);
							this.fetchClosedSalePartStocks(this.openSalePartStockPagination.current_page);
              this.changeView('open');
            })
						.catch((error) => {
							this.message = "An Error Occurred";
							this.color = "error";
							this.snackbar = true;
							this.rejectRefundStockLoading = false;
							this.activate = false;
							this.refundPartStockIndex = null;
							console.log(error.response);
              this.changeView('open');
            });
			}
		},

		getFormData() {
			apiCall({
				url: "/api/sales?type=formData",
				method: "GET",
			})
					.then(r => this.formData = r)
					.catch(() => {
						this.message = "An Error Occurred; Couldn't Get Form Data";
						this.color = "error";
					});
		},
		viewLog(data) {
			this.logData.message = data.id;
			this.logData.model = "App\\Models\\Sale"
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},
	},
	computed: {
		...mapGetters([
			"openSalePartStocks", "openSalePartStockPagination", "openSalePartStockLoader",
			"closedSalePartStocks", "closedSalePartStockPagination", "closedSalePartStockLoader",
			"logDataModel"
		]),
		dateToday() {
			var today = new Date();

			var timestamp =
					today.getFullYear() +
					"-" +
					this.months[today.getMonth()] +
					"-" +
					("0" + today.getDate()).slice(-2);
			return timestamp;
		},

		openSaleLength: function () {
			return Math.ceil(
					this.openSalePartStockPagination.total / this.openSalePartStockPagination.per_page
			);
		},
		closedSaleLength: function () {
			return Math.ceil(
					this.closedSalePartStockPagination.total / this.closedSalePartStockPagination.per_page
			);
		},
	},
};
</script>
  