<template>
	<component :is="component"/>
</template>
<style>
/* width */
::-webkit-scrollbar {
	width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
<script>
import { mapGetters } from "vuex";
import Home from "@/views/Home.vue";
import CreateSale from "@/views/CreateSale.vue";
import { Roles } from "@/constants/Roles";

export default {
	components: {
		Home,
		CreateSale,
	},
	data() {
		return {
			component:null
		}
	},
	computed: {
		...mapGetters(['getProfile']),
	},
	created() {
		if(this.getProfile.roles.some(r => r.name === Roles.POS_STANDARD_USER)) {
			this.component = "CreateSale";
		} else {
			this.component = "Home";
		}
	},
};
</script>
  